/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import {
  useWallet,
  useConnectedWallet,
  useLCDClient,
} from "@terra-money/wallet-provider";
import { contractAddress } from "./contract/address";
import { DistributeClient } from "./contract/clients/DistributeClient";
import { Coins, LCDClient } from "@terra-money/terra.js";
import { Unity, useUnityContext } from "react-unity-webgl";

const UNITY_OBJECT_NAME = "GameController";

const SITE_KEY = "6LfwXu4jAAAAAAJ-qdmEW-AyTbq4tnf8gvVxBiKn";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const getToken = () => {
  return new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token: string) => {
          resolve(token);
        });
    });
  });
};

const App = () => {
  const [count, setCount] = useState(0);
  const [currentAddress, setCurrentAddress] = useState("");
  // const [updating, setUpdating] = useState(true)
  // const [resetValue, setResetValue] = useState(0)

  // const [walletLogin, setWalletLogin] = useState({
  //   noState: true,
  //   error: false,
  // });
  const connectedWallet = useConnectedWallet();
  const [isLoginCalled, setIsLoginCalled] = useState(false);
  const [startDepositProcess, setStartDepositProcess] = useState({
    start: false,
    data: "",
  });
  const [startFetchJackpotData, setStartFetchJackpotData] = useState(false);

  const [message, setMessage] = useState({
    functionName: "",
    messageToSend: "",
  });

  useEffect(() => {
    const loadScriptByURL = (id: any, url: any, callback: any) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      async function () {
        console.log("Script loaded!");
        const token = await getToken();
        console.log("token", token);
      }
    );
  }, []);

  useEffect(() => {
    if (!startFetchJackpotData) {
      return;
    }
    setStartFetchJackpotData(false);
    queryContract();
  }, [startFetchJackpotData]);

  useEffect(() => {
    if (!startDepositProcess.start) {
      return;
    }
    let playfabId;

    try {
      const { playFabId, walletId } = JSON.parse(startDepositProcess.data);
      playfabId = playFabId;
      if (!connectedWallet || connectedWallet.walletAddress !== walletId) {
        return setMessage({
          functionName: "onDepositFailed",
          messageToSend: "Wallet Changed",
        });
      }
    } catch (err) {
      console.log(err);
      setMessage({
        functionName: "onDepositFailed",
        messageToSend: "Invalid data passed for deposit transaction",
      });
    }
    // playfabId
    setStartDepositProcess({
      start: false,
      data: "{}",
    });
    depositTransaction(playfabId);
  }, [startDepositProcess]);

  useEffect(() => {
    if (!message.messageToSend || !message.functionName) {
      return;
    }
    return sendMessage(
      UNITY_OBJECT_NAME,
      message.functionName,
      message.messageToSend
    );
  }, [message]);

  useEffect(() => {
    setCurrentAddress(connectedWallet?.walletAddress || "");
    console.log("Connected Wallet Change Called");
    if (!isLoginCalled) {
      return;
    }
    setIsLoginCalled(false);

    console.log("connected wallet", connectedWallet?.walletAddress);

    if (!connectedWallet) {
      return setMessage({
        functionName: "OnWalletConnectedFailure",
        messageToSend: "Unable to connect to wallet",
      });
    }

    setMessage({
      functionName: "OnWalletConnectedSuccess",
      messageToSend: connectedWallet.walletAddress,
    });
  }, [connectedWallet]);

  const UnityContext = useUnityContext({
    loaderUrl: "build/Burnicorn2022.loader.js",
    dataUrl: "build/Burnicorn2022.data",
    frameworkUrl: "build/Burnicorn2022.framework.js",
    codeUrl: "build/Burnicorn2022.wasm",
  });

  const { addEventListener, sendMessage, unityProvider } = UnityContext;

  addEventListener("jackpotData", () => {
    setStartFetchJackpotData(true);
  });

  addEventListener("connectWallet", async () => {
    await connectWallet();
    setIsLoginCalled(true);
  });

  addEventListener("walletStatus", () => {
    return sendMessage(UNITY_OBJECT_NAME, "onWalletStatusChange", status);
  });

  const { status, availableConnectTypes, connect, refetchStates, disconnect } =
    useWallet();

  const timer = () => {
    refetchStates();
    setCount(count > 1000 ? 1 : count + 1);
  };

  useEffect(() => {
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [count]);

  addEventListener("depositTransaction", async (stringifiedJSON) => {
    setStartDepositProcess({
      start: true,
      data: stringifiedJSON,
    });
  });

  const lcd = useLCDClient();

  const contractClient = useMemo(() => {
    if (!connectedWallet) {
      return;
    }
    let a = new DistributeClient(
      lcd,
      connectedWallet,
      contractAddress("distribute", connectedWallet)
    );
    return a;
  }, [lcd, connectedWallet]);

  const connectWallet = async () => {
    try {
      const response = await connect(availableConnectTypes[0]);
      return { success: true, data: response };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const canPlayGame = async (walletId: string) => {
    try {
      const { data } = await axios.get(
        `https://game.burnicorn.io/api/player/transaction?walletId=${walletId}`
      );
      return setMessage({
        functionName: "CanUserPlayGame",
        messageToSend: JSON.stringify({
          canPlayGame: data.canPlay || false,
        }),
      });
    } catch (err) {}

    return setMessage({
      functionName: "CanUserPlayGame",
      messageToSend: JSON.stringify({
        canPlayGame: false,
      }),
    });
  };

  const updateGameStatua = async (walletId: string) => {
    try {
      await axios.patch("https://game.burnicorn.io/api/player/transaction", {
        walletId,
      });
      return setMessage({
        functionName: "OnStatusUpdateSuccess",
        messageToSend: "Status updated successfully",
      });
    } catch (err) {}

    return setMessage({
      functionName: "OnStatusUpdateFailure",
      messageToSend: "Unable to updated status for user",
    });
  };

  addEventListener("disconnectWallet", async () => {
    try {
      await disconnect();
      console.log("succesfully disconnected");
      return setMessage({
        functionName: "onWalletDisconnected",
        messageToSend: "Wallet disconnected successfully",
      });
    } catch (error) {
      console.log("wallet disconnect error", error);
      setMessage({
        functionName: "onWalletDisconnected",
        messageToSend: "Error while disconnecting wallet",
      });
    }
  });

  addEventListener(
    "updateGameScore",
    async (playfabId: string, statisticName: string, score: number) => {
      try {
        const token = await getToken();
        await fetch("https://game.burnicorn.io/api/player/score", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ playfabId, statisticName, score, token }),
        });
        return setMessage({
          functionName: "onScoreUpdateSuccess",
          messageToSend: "Score successfully updated",
        });
      } catch (err) {
        console.log("error while updating score", err);
      }
      return setMessage({
        functionName: "onScoreUpdateFail",
        messageToSend: JSON.stringify({ playfabId, statisticName, score }),
      });
    }
  );

  addEventListener("canPlayGame", canPlayGame);
  addEventListener("updateGameStatus", updateGameStatua);

  // const disconnectWallet = async () => {
  //   try {
  //     await disconnect();
  //     console.log("succesfully disconnected");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const walletStatus = async () => {
  //   console.log(status);
  // };

  // const withdrawTransaction = async (address: string) => {
  //   try {
  //     // if (status == "WALLET_CONNECTED") {
  //     if (contractClient) {
  //       await contractClient.withdraw({ receiver: address });
  //     }
  //     // }
  //     else {
  //       console.log("Wallet not connected");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const depositTransaction = async (playfabId: string) => {
    try {
      if (contractClient) {
        const transaction = await contractClient.deposit();
        const txHash = transaction?.txhash;
        return setMessage({
          functionName: "onDepositStarted",
          messageToSend: "Deposit started with hash " + txHash,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setMessage({
      functionName: "onDepositFailed",
      messageToSend: "Deposit failed",
    });
  };

  addEventListener("FetchGlobalData", () => {
    queryContract();
  });

  addEventListener("FetchLimitData", () => {
    getContractLimit();
  });

  const queryContract = async () => {
    try {
      const gasPrices = await (
        await fetch("https://pisco-api.terra.dev/gas-prices", {
          redirect: "follow",
        })
      ).json();
      const gasPricesCoins = new Coins(gasPrices);

      const lcd = new LCDClient({
        URL: "https://pisco-lcd.terra.dev/",
        chainID: "pisco-1",
        gasPrices: gasPricesCoins,
        gasAdjustment: "1.5",
        // gas: "10000000",
        isClassic: false, // optional parameter, false by default
      });
      const ContractAddress =
        "terra1jtn5kjnstqaqeuu03sgf8mcy80axvc5mazncw6qvmxa44n0ughysysz3gt";
      const response = await lcd.wasm.contractQuery(ContractAddress, {
        records: {},
      });
      console.log(response);
      setMessage({
        functionName: "OnGlobalDataChange",
        messageToSend: JSON.stringify(response),
      });
    } catch (error) {
      console.log(error);
      try {
        setMessage({
          functionName: "OnGlobalDataChangeFailed",
          messageToSend: "Failed while fetching jackpot details",
        });
      } catch (err) {
        console.log("Error while sending back the message", err);
      }
    }
  };

  const getContractLimit = async () => {
    try {
      const gasPrices = await (
        await fetch("https://pisco-api.terra.dev/gas-prices", {
          redirect: "follow",
        })
      ).json();
      const gasPricesCoins = new Coins(gasPrices);

      const lcd = new LCDClient({
        URL: "https://pisco-lcd.terra.dev/",
        chainID: "pisco-1",
        gasPrices: gasPricesCoins,
        gasAdjustment: "1.5",
        // gas: "10000000",
        isClassic: false, // optional parameter, false by default
      });
      const ContractAddress =
        "terra1jtn5kjnstqaqeuu03sgf8mcy80axvc5mazncw6qvmxa44n0ughysysz3gt";
      const response = await lcd.wasm.contractQuery(ContractAddress, {
        limit: {},
      });
      console.log(response);
      setMessage({
        functionName: "OnLimitDataChange",
        messageToSend: JSON.stringify(response),
      });
    } catch (error) {
      console.log(error);
      try {
        setMessage({
          functionName: "OnLimitDataChangeFailed",
          messageToSend: "Failed while fetching limit details",
        });
      } catch (err) {
        console.log("Error while sending back the message", err);
      }
    }
  };

  const { innerWidth: width, innerHeight: height } = window;
  return (
    <div>
      <Unity
        className="unity"
        unityProvider={unityProvider}
        style={{ width, height }}
      />
    </div>
  );
};

export default App;
