/**
 * This file was automatically generated by @octalmage/terra-cosmwasm-typescript-gen@0.1.1.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @octalmage/terra-cosmwasm-typescript-gen generate command to regenerate this file.
 */

import {
  LCDClient,
  Coins,
  Wallet,
  MsgExecuteContract,
  TxInfo,
  WaitTxBroadcastResult,
} from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
function isConnectedWallet(x: Wallet | ConnectedWallet): x is ConnectedWallet {
  return typeof (x as Wallet).key === "undefined";
}
async function waitForInclusionInBlock(
  lcd: LCDClient,
  txHash: string
): Promise<TxInfo | undefined> {
  let res;
  for (let i = 0; i <= 50; i++) {
    try {
      res = await lcd.tx.txInfo(txHash);
    } catch (error) {
      // NOOP
    }

    if (res) {
      break;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));
  }

  return res;
}
export type Uint128 = string;
export type Addr = string;
export interface Config {
  amount: Uint128;
  arbiter: Addr;
  burn: Addr;
  development: Addr;
  [k: string]: unknown;
}
export type ExecuteMsg =
  | {
      deposit: {
        [k: string]: unknown;
      };
    }
  | {
      withdraw: {
        amount: Uint128;
        receiver: string;
        [k: string]: unknown;
      };
    }
  | {
      set: {
        amount: Uint128;
        [k: string]: unknown;
      };
    };
export interface InstantiateMsg {
  amount: Uint128;
  arbiter: string;
  burn: string;
  development: string;
  [k: string]: unknown;
}
export type QueryMsg =
  | {
      accounts: {
        [k: string]: unknown;
      };
    }
  | {
      records: {
        [k: string]: unknown;
      };
    }
  | {
      limit: {
        [k: string]: unknown;
      };
    };
export interface DistributeReadOnlyInterface {
  contractAddress: string;
  accounts: () => Promise<any>;
  records: () => Promise<any>;
  limit: () => Promise<any>;
}
export class DistributeQueryClient implements DistributeReadOnlyInterface {
  client: LCDClient;
  contractAddress: string;

  constructor(client: LCDClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.accounts = this.accounts.bind(this);
    this.records = this.records.bind(this);
    this.limit = this.limit.bind(this);
  }

  accounts = async (): Promise<any> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      accounts: {},
    });
  };
  records = async (): Promise<any> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      records: {},
    });
  };
  limit = async (): Promise<any> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      limit: {},
    });
  };
}
export interface DistributeInterface extends DistributeReadOnlyInterface {
  contractAddress: string;
  deposit: (funds?: Coins) => Promise<any>;
  withdraw: (
    {
      amount,
      receiver,
    }: {
      amount: string;
      receiver: string;
    },
    funds?: Coins
  ) => Promise<any>;
  set: (
    {
      amount,
    }: {
      amount: string;
    },
    funds?: Coins
  ) => Promise<any>;
}
export class DistributeClient
  extends DistributeQueryClient
  implements DistributeInterface
{
  client: LCDClient;
  wallet: Wallet | ConnectedWallet;
  contractAddress: string;

  constructor(
    client: LCDClient,
    wallet: Wallet | ConnectedWallet,
    contractAddress: string
  ) {
    super(client, contractAddress);
    this.client = client;
    this.wallet = wallet;
    this.contractAddress = contractAddress;
    this.deposit = this.deposit.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.set = this.set.bind(this);
  }

  deposit = async (
    funds?: Coins
  ): Promise<WaitTxBroadcastResult | TxInfo | undefined> => {
    let totalLunaToDeposit: string;
    try {
      const gasPrices = await (
        await fetch("https://pisco-api.terra.dev/gas-prices", {
          redirect: "follow",
        })
      ).json();
      const gasPricesCoins = new Coins(gasPrices);

      const lcd = new LCDClient({
        URL: "https://pisco-lcd.terra.dev/",
        chainID: "pisco-1",
        gasPrices: gasPricesCoins,
        gasAdjustment: "1.5",
        // gas: "10000000",
        isClassic: false, // optional parameter, false by default
      });

      const response: { limit: string } = await lcd.wasm.contractQuery(
        this.contractAddress,
        {
          limit: {},
        }
      );
      if (!response.limit || isNaN(+response.limit)) {
        throw new Error("Undefined limit found");
      }
      totalLunaToDeposit = response.limit;
    } catch (error) {
      console.log(error);
      throw error;
    }

    const senderAddress = isConnectedWallet(this.wallet)
      ? this.wallet.walletAddress
      : this.wallet.key.accAddress;
    const execMsg = new MsgExecuteContract(
      senderAddress,
      this.contractAddress,
      {
        deposit: {},
      },
      new Coins({ uluna: totalLunaToDeposit })
    );

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post({
        msgs: [execMsg],
      });
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } else {
      const execTx = await this.wallet.createAndSignTx({
        msgs: [execMsg],
      });
      return this.client.tx.broadcast(execTx);
    }
  };
  withdraw = async (
    {
      amount,
      receiver,
    }: {
      amount: string;
      receiver: string;
    },
    funds?: Coins
  ): Promise<WaitTxBroadcastResult | TxInfo | undefined> => {
    const senderAddress = isConnectedWallet(this.wallet)
      ? this.wallet.walletAddress
      : this.wallet.key.accAddress;
    const execMsg = new MsgExecuteContract(
      senderAddress,
      this.contractAddress,
      {
        withdraw: {
          amount,
          receiver,
        },
      },
      funds
    );

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post({
        msgs: [execMsg],
      });
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } else {
      const execTx = await this.wallet.createAndSignTx({
        msgs: [execMsg],
      });
      return this.client.tx.broadcast(execTx);
    }
  };
  set = async (
    {
      amount,
    }: {
      amount: string;
    },
    funds?: Coins
  ): Promise<WaitTxBroadcastResult | TxInfo | undefined> => {
    const senderAddress = isConnectedWallet(this.wallet)
      ? this.wallet.walletAddress
      : this.wallet.key.accAddress;
    const execMsg = new MsgExecuteContract(
      senderAddress,
      this.contractAddress,
      {
        set: {
          amount,
        },
      },
      funds
    );

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post({
        msgs: [execMsg],
      });
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } else {
      const execTx = await this.wallet.createAndSignTx({
        msgs: [execMsg],
      });
      return this.client.tx.broadcast(execTx);
    }
  };
}
